$(function() {
	// -------------------------------------------
	// 共通関数定義 ※2022/06現在、以下で使用
	//   ・PC/SMP賃貸配下(Spring)
	//   ・PC/SMP不動産検索配下(Spring)
	//   ・PC/SMPマイページ配下(Spring)
	//   ・PC/SMP不動産トップ(CMS)
	//   ・PC/SMP売買配下(CMS)
	// -------------------------------------------
	window.Nifty = window.Nifty || {};
	window.Nifty.Utils = window.Nifty.Utils || {};

	// 共通: 環境(開発/本番)
	Nifty.Utils.Env = {
		// 本番環境以外の環境か
		isDev: function() {
			return (location.hostname != 'myhome.nifty.com');
		}
		// スマホか
		,isSmp: function() {
			var userAgent = navigator.userAgent;
			return (userAgent.indexOf('iPhone') >= 0 || (userAgent.indexOf('Android') >= 0 && userAgent.indexOf('Mobile') >= 0));
		}
		,isApp: function() {
			return navigator.userAgent.includes('NiftyAppMobile');
		}
		,Device: {
			PC: 'PC'
			,SMP: 'SMP'
			,IOS_RENT_APP: 'IOS_RENT_APP'
			,IOS_BUY_APP: 'IOS_BUY_APP'
			,ANDROID_RENT_APP: 'ANDROID_RENT_APP'
			,ANDROID_BUY_APP: 'ANDROID_BUY_APP'
			,getType: function() {
				if (Nifty.Utils.Env.isApp()) {
					if (navigator.userAgent.indexOf('com.nifty.myhome.rent.android') >= 0) {
						return this.ANDROID_RENT_APP;
					} else if (navigator.userAgent.indexOf('com.nifty.myhome.buy.android') >= 0) {
						return this.ANDROID_BUY_APP;
					} else if (navigator.userAgent.indexOf('com.nifty.myhome.rent') >= 0) {
						return this.IOS_RENT_APP;
					} else {
						return this.IOS_BUY_APP;
					}
				}
				if (Nifty.Utils.Env.isSmp()) {
					return this.SMP;
				}
				return this.PC;
			}
		}
		,getKarteDeviceType: function() {
			const deviceType = this.Device.getType();
			if (deviceType == this.Device.IOS_RENT_APP) return 'ios_rent_app';
			if (deviceType == this.Device.IOS_BUY_APP) return 'ios_buy_app';
			if (deviceType == this.Device.ANDROID_RENT_APP) return 'android_rent_app';
			if (deviceType == this.Device.ANDROID_BUY_APP) return 'android_buy_app';
			if (deviceType == this.Device.SMP) return 'smp';
			return 'pc';
		}
	};

	// 共通: 配列操作
	Nifty.Utils.Array = {
		// 重複削除
		unique: function(array) {
			return array.filter(function(elem, index, self) {
				return self.indexOf(elem) === index;
			});
		}
		// 空白削除
		,removeEmpty: function(array) {
			return array.filter(Boolean);
		}
		// array1にarray2の要素を全て含むか
		,containsAll: function(array1, array2) {
			for (var idx = 0, max = array2.length; idx < max; idx++) {
				if (array1.indexOf(array2[idx]) === -1) {
					return false
				}
			}
			return true;
		}
		// array1にarray2の要素のいずれかを含むか
		,containsAny: function(array1, array2) {
			for (var idx = 0, max = array2.length; idx < max; idx++) {
				if (array1.indexOf(array2[idx]) !== -1) {
					return true
				}
			}
			return false;
		}
		// array1とarray2の要素が全て同じか
		,equalsAll: function(array1, array2) {
			return (array1.length == array2.length && array1.filter(i => array2.indexOf(i) == -1).length == 0);
		}
	};

	// 共通: HTML関連
	Nifty.Utils.Html = {
		// HTMLエスケープ
		escape : function(html) {
			var elem = document.createElement('div');
			elem.appendChild(document.createTextNode(html));
			return elem.innerHTML.replace(/"/g, '&quot;').replace(/'/g, '&#x27;');
		}
		// HTMLアンエスケープ
		,unescape : function(str) {
			var div = document.createElement("div");
			div.innerHTML = str.replace(/</g,"&lt;")
				.replace(/>/g,"&gt;")
				.replace(/ /g, "&nbsp;")
				.replace(/\r/g, "&#13;")
				.replace(/\n/g, "&#10;");
			return div.textContent || div.innerText;
		}
		// iOSで100vh使用時にアドレスバーを加味する為のProperty設定
		//   https://coliss.com/articles/build-websites/operation/css/css-cover-the-entire-height-of-the-screen.html
		,setVh : function() {
			if (!this._isSetVhDone) {
				this._isSetVhDone = true;

				var setVhProperty = function() {
					var vh = window.innerHeight * 0.01;
					document.documentElement.style.setProperty('--vh', `${vh}px`);
				};
				setVhProperty();
				$(window).on('resize', function() {
					setVhProperty();
				});
			}
		}
		// optionタグの表示/非表示
		// ※Mac Safari、iOS Safariでoptionに対するdisplay: none;が効かない為、spanで囲う事で非表示にする
		//   https://blog.supersonico.info/archives/3864/
		,setOptionTagVisibility : function($target, isVisible) {
			if ($target.get(0).tagName.toLowerCase() != 'option') {
				return;
			}
			var $parent = $target.parent();
			var parentTagName = $parent.get(0).tagName.toLowerCase();

			if (isVisible) {
				$target.show();
				if (parentTagName == 'span') {
					$parent.after($target);
					$parent.remove();
				}
			} else {
				$target.hide();
				if (parentTagName != 'span') {
					$target.wrap('<span>');
					$target.parent().hide();
				}
			}
		}
	};

	// 共通: 汎用的なAnimation処理
	Nifty.Utils.Animation = {
		// $targetの値をcountの値になるまでdurationミリ秒かけてカウントアップ(またはカウントダウン)
		animateCountUpOrDown : function($target, count, duration) {
			var before = parseInt($target.eq(0).text().replace(/,/g, ''));
			var after = count;
			$({count: before}).animate(
				{count: after},
				{
					duration: duration,
					easing: 'linear',
					progress: function() {
						$target.text(Math.ceil(this.count).toLocaleString());
					}
				}
			);
		}
	};

	// 共通: Cookie操作
	// TypeScript の場合は /assets2/common/assets/ts/module/cookie-util.ts を利用可能
	Nifty.Utils.Cookie = {
		get: function(name) {
			var pairs = document.cookie.split(';');
			for (var idx = 0, max = pairs.length; idx < max; idx++) {
				var pair = pairs[idx].trim();
				var key = pair.split('=', 1)[0];
				if (key == name) {
					return decodeURIComponent(pair.replace(key + '=', ''));
				}
			}
			return '';
		}
		,set: function(name, value, path, maxAge, domain) {
			// RequestBeanによるcookie操作時はdomainが設定される為、サーバ側でも操作するcookieにはdomainを指定する事(domain設定の有無の違いがあると正しく削除等が行われない)
			document.cookie = name + '=' + encodeURIComponent(value) + '; path=' + (path ? path : '/') + ((typeof maxAge != 'undefined') ? ('; max-age=' + maxAge) : '') + (domain ? ('; domain=' + domain) : '');
		}
		,remove: function(name, path, domain) {
			this.set(name, '', path, 0, domain);
		}
	};

	// 共通: 日付操作
	Nifty.Utils.Date = {
		_format: {
			hh: function(date) { return ('0' + date.getHours()).slice(-2); }
			,h: function(date) { return date.getHours(); }
			,mm: function(date) { return ('0' + date.getMinutes()).slice(-2); }
			,m: function(date) { return date.getMinutes(); }
			,ss: function(date) { return ('0' + date.getSeconds()).slice(-2); }
			,dd: function(date) { return ('0' + date.getDate()).slice(-2); }
			,d: function(date) { return date.getDate(); }
			,s: function(date) { return date.getSeconds(); }
			,yyyy: function(date) { return date.getFullYear() + ''; }
			,yy: function(date) { return date.getYear() + ''; }
			,t: function(date) { return date.getDate()<=3 ? ['st', 'nd', 'rd'][date.getDate()-1]: 'th'; }
			,w: function(date) { return ['Sun', '$on', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][date.getDay()]; }
			,MMMM: function(date) { return ['January', 'February', '$arch', 'April', '$ay', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][date.getMonth()]; }
			,MMM: function(date) { return ['Jan', 'Feb', '$ar', 'Apr', '$ay', 'Jun', 'Jly', 'Aug', 'Spt', 'Oct', 'Nov', 'Dec'][date.getMonth()]; }
			,MM: function(date) { return ('0' + (date.getMonth() + 1)).slice(-2); }
			,M: function(date) { return date.getMonth() + 1; }
			,$: function(date) { return 'M'; }
		}
		,_priority: ['hh', 'h', 'mm', 'm', 'ss', 'dd', 'd', 's', 'yyyy', 'yy', 't', 'w', 'MMMM', 'MMM', 'MM', 'M', '$']

		// 日付フォーマット指定
		,format: function(date, format) {
			var self = this;
			return this._priority.reduce(function(res, fmt) {
				return res.replace(fmt, self._format[fmt](date));
			}, format);
		}
	};

	// 共通: ファイル関連
	Nifty.Utils.file = {
		// script読み込み(同期処理)
		// ※[2022/06追記] async: falseによる同期処理は効かない為、利用時は留意する事
		loadScript : function(src) {
			$.ajax({
				url: src
				,dataType: 'script'
				,async: false
			});
		}
	};

	// 共通: Ajax関連
	Nifty.Utils.Ajax = {
		_procCounter: {}
		,_loadScriptStatus: {}
		// ajax(並行実行時に最後の実行のみコールバック実行)
		// ※結果出力先HTML要素が同じ場合など、コールバックの呼び出し順により不整合が生じる場合に使用
		,ajax: function(procName, option) {
			if (!this._procCounter[procName]) {
				this._procCounter[procName] = 0;
			}
			var currentProcCount = ++this._procCounter[procName];
			var procCounter = this._procCounter;

			var success = option.success;
			var error = option.error;
			var complete = option.complete;

			// 同プロセス名でカウンターが最大の場合のみコールバックを実行
			return $.ajax(Object.assign(option, {
				success: (typeof success == 'function') ? function(res) {
					if (currentProcCount == procCounter[procName]) {
						success(res);
					}
				} : null
				,error: (typeof error == 'function') ? function() {
					if (currentProcCount == procCounter[procName]) {
						error();
					}
				} : null
				,complete: (typeof complete == 'function') ? function() {
					if (currentProcCount == procCounter[procName]) {
						complete();
					}
				} : null
			}));
		}
		// ajax(指定時間の間に同じprocNameの呼び出しが無い場合のみajax送信を実行)
		// ※主に負荷対策として使用(マンションページのカンタン検索で、検プラ負荷軽減のため指定時間入力変更が無くなるまで検索実行を待機させる、等)
		,ajaxWithDelay: function(procName, option, delayMilliSeconds) {
			if (!this._procCounter[procName]) {
				this._procCounter[procName] = 0;
			}
			var currentProcCount = ++this._procCounter[procName];
			var procCounter = this._procCounter;

			setTimeout(function() {
				// 同プロセス名でカウンターが最大の場合のみajax送信を実行
				if (currentProcCount == procCounter[procName]) {
					return $.ajax(option);
				}
			}, delayMilliSeconds);
		}
		,cancelAjaxWithDelay: function(procName) {
			// 発行済みの遅延ajax送信をキャンセル(カウンターを0にする事でajaxWithDelayの実行判定がfalseとなるようにする)
			this._procCounter[procName] = 0;
		}
		// script読み込み(多重読み込み防止)
		,loadScript: function(path, callback) {
			var self = this;
			if (self._loadScriptStatus[path] == 'DONE') {
				callback();
			} else if (self._loadScriptStatus[path] == 'DOING') {
				var timer = setInterval(function() {
					if (self._loadScriptStatus[path] == 'DONE') {
						callback();
						clearInterval(timer);
					}
				}, 500);
			} else {
				self._loadScriptStatus[path] = 'DOING';
				$.ajax({
					url: path
					,dataType: 'script'
					,cache : true
					,success: function() {
						self._loadScriptStatus[path] = 'DONE';
						callback();
					}
				});
			}
		}
	};

	// 共通: 実行制御関連
	Nifty.Utils.Execute = {
		/**
		 * checkFuncの戻り値がtrueになるまでexecFuncの実行を待機
		 * ・使用例: MyhomeTDMが使用可能になるまでTDへのイベント送信を待機する
		 *   Nifty.Utils.Execute.waitExecUntilCheckTrue(
		 *     function() {
		 *       window.MyhomeTDM.Tracking.actionTrack(table, mh_event_type, mh_event_category, mh_event_name, mh_event_value, mh_event_psid);
		 *     },
		 *     function() {
		 *       return (typeof window.MyhomeTDM != 'undefined');
		 *     }
		 *   );
		 * @param execFunc 実行関数
		 * @param checkFunc チェック関数
		 * @param intervalMilliSec チェック間隔ミリ秒(デフォルト: 100)
		 * @param limitMilliSec 待機上限ミリ秒(デフォルト: 5000) ※上限まで待ってもcheckFuncの戻り値がtrueとならない場合はexecFuncを実行しない
		 */
		waitExecUntilCheckTrue: function(execFunc, checkFunc, intervalMilliSec, limitMilliSec) {
			if (typeof execFunc != 'function' || typeof checkFunc != 'function') {
				return;
			}
			if (checkFunc()) {
				// 既に条件を満たしている場合は即時実行
				execFunc();
				return;
			}

			var interval = (intervalMilliSec) ? intervalMilliSec : 100;
			var limit = (limitMilliSec) ? limitMilliSec : 5000;
			var counter = 0;
			var timer = setInterval(function() {
				if (checkFunc()) {
					clearInterval(timer);
					execFunc();
				} else {
					counter++;
					if ((interval * counter) > limit) {
						// 未完了
						clearInterval(timer);
					}
				}
			}, interval);
		}
	};

	// 共通: URL関連
	Nifty.Utils.Url = {
		// パラメータ判定
		hasParam: function(url, param) {
			var urlData = url.split('?');
			return (urlData.length == 2 && urlData[1].split('&').indexOf(param) >= 0);
		}
		// パラメータ取得
		// 複数同じキーがある場合は最初にヒットしたものが返却される
		,getParam: function(url, key) {
			var urlData = url.split('?');
			if (urlData == undefined || 2 != urlData.length) {
				return '';
			}
			var paramsArray = urlData[1].split('&');
			for (var param of paramsArray) {
				var splited = param.split('=');
				if (splited[0] == key && splited[1] != undefined) {
					return splited[1];
				}
			}
			return '';
		}
		// パラメータ追加
		,addParam: function(url, param) {
			if (!param) {
				return url;
			}
			return url + (url.indexOf('?') < 0 ? '?' : '&') + param;
		}
		// パラメータ名変換
		,renameParam: function(url, fromName, toName) {
			if (fromName && toName) {
				return url = url.replace(new RegExp('(^|[?&])' + fromName + '(=[^&]*)(&|$)', 'g'), '$1' + toName + '$2$3').replace(/[?&]+$/, '');
			}
			return url
		}
		// パラメータ除去
		,removeParams: function(url, params) {
			if (url && params) {
				for (var idx = 0, max = params.length; idx < max; idx++) {
					url = url.replace(new RegExp('(^|[?&])' + params[idx] + '=[^&]*(&|$)', 'g'), '$1').replace(/[?&]+$/, '');
				}
			}
			return url
		}
		,removeParam: function(url, param) {
			return (!param) ? url : this.removeParams(url, [ param ]);
		}
		// パラメータ絞り込み
		,filterParams: function(url, params) {
			if (params) {
				var pairs = [];
				for (var idx = 0, max = params.length; idx < max; idx++) {
					var regexp = new RegExp('(^|[?&])(' + params[idx] + '=[^&]*)(&|$)', 'g');
					var matches;
					while ((matches = regexp.exec(url)) != null) {
						pairs.push(matches[2]);
					}
				}
				url = ((url.indexOf('?') >= 0 ? (url.split('?')[0] + '?') : '') + pairs.join('&')).replace(/[?&]+$/, '');
			}
			return url
		}
		,filterParam: function(url, param) {
			return (!param) ? url : this.filterParams(url, [ param ]);
		}
		// クエリパラメータの値が等しいか比較する
		,areEqualUrlParam: function(url1, url2, param) {
			if (!url1 || !url2) {
				return false;
			}
			var urlParam1 = this.getParam(url1, param);
			var urlParam2 = this.getParam(url2, param);
			return urlParam1.toString() == urlParam2.toString();
		}
		// クエリパラメータが同じでなくても比較できるようにする
		,areEqualUrlsIgnoringParam: function(url1, url2) {
			if (!url1 || !url2) {
				return false;
			}
			var urlsIgnoringParam1 = url1.replace(/\?.*$/,"");
			var urlsIgnoringParam2 = url2.replace(/\?.*$/,"");
			return urlsIgnoringParam1.toString() == urlsIgnoringParam2.toString();
		}
		// クエリパラメータの並び順が同じでなくても比較できるようにする
		,areEqualUrlsIgnoringParamOrder: function(url1, url2) {
			if (!url1 || !url2) {
				return false;
			}
			var paramMap1 = url1.split(/[?&]/).sort();
			var paramMap2 = url2.split(/[?&]/).sort();
			return paramMap1.toString() == paramMap2.toString();
		}
		// URLにお気に入り物件を付与する
		,getInquiryUrlWithKeepPropertyParams: function(propertyHref) {
			const favoriteProperties = Nifty.Rent.Storage.getItems(Nifty.Rent.Storage.Keys.BUKKEN_FAVORITE);
			const favoritePropertyUrls = [];
			// PCの場合、お気に入りレコメンド最大件数:18件
			const maxFavoriteNum = 18;
			// お気に入り度順に並び替え
			favoriteProperties.sort((a, b) => b.keepLevel - a.keepLevel);

			// お気に入り物件の取得処理
			for (let idx = 0; idx < favoriteProperties.length; idx++) {
				// お気に入りレコメンド最大件数まで付与する
				if (favoritePropertyUrls.length >= maxFavoriteNum) break;
				const item = favoriteProperties[idx];

				// 掲載期限を過ぎた物件を除外
				if (item.expireDate && Nifty.Rent.Storage.calcBukkenExpireDays(item) < 0) {
					continue;
				}
				const favoritePropertyUrl = item.inquiryUrl.substring((item.inquiryUrl.indexOf('myhome.nifty.com/rent/') + 'myhome.nifty.com/rent/'.length), item.inquiryUrl.lastIndexOf('/inquiry'));
				// 問合せしようとしている物件を除外
				if (propertyHref.includes(favoritePropertyUrl)) {
					continue;
				}
				favoritePropertyUrls.push(favoritePropertyUrl);
			}
			if (favoritePropertyUrls.length > 0) {
				propertyHref = Nifty.Utils.Url.addParam(propertyHref, 'keep_property_keys='+ favoritePropertyUrls.join(','));
			}
			return propertyHref;
		}
	};

	// 共通: History API関連
	Nifty.Utils.History = {
		// 利用可能判定
		isEnabled: function() {
			return (window.history && window.history.pushState);
		}
		// ブラウザのアドレスバーのURLを上書き + 履歴追加
		,setPrevNextState: function(prevState, nextState, nextUrl) {
			if (this.isEnabled()) {
				// 履歴上、今いるページのstateを設定
				// 補足: ブラウザの「戻る」「進む」で今いるページに戻ったときの popstate イベントの e.state で取得する
				// 補足: これをしないと、ブラウザバックした後、 Nifty.Rent.History.setPopstate() でページ更新できず、アドレスバーのURLだけが書き換えられて画面が変わらない
				window.history.replaceState(prevState, null, location.pathname + location.search);
				// 新しいページのURLとstateを履歴に追加
				window.history.pushState(nextState, null, nextUrl);
			}
		}
		// ブラウザのアドレスバーのURLを上書き (履歴追加しない)
		,replaceCurrentUrl: function(newUrl) {
			if (this.isEnabled()) {
				window.history.replaceState(null, null, newUrl);
			}
		}
	};

	// 共通: データ関連
	Nifty.Utils.Data = {
		_API_AREAS_DATA_URL: '/common/api/js/areas/data.js?' + Nifty.Utils.Date.format(new Date(), 'yyyyMMdd')

		,loadAreasData: function(callback) {
			Nifty.Utils.Ajax.loadScript(this._API_AREAS_DATA_URL, callback);
		}
		,getAreasData: function() {
			return Nifty.Data.AreasData;
		}
		,getFacetData: function() {
			return (Nifty.Data.Facet) ? Nifty.Data.Facet : {};
		}
		,getDataValue: function(data, conf, key) {
			return data[conf.indexOf(key)];
		}
		,createDataItem: function(data, conf, id) {
			var item = { id: id };
			for (var idx = 0, max = data.length; idx < max; idx++) {
				item[conf[idx]] = data[idx];
			}
			return item;
		}
		,createDataItems: function(datas, conf) {
			var dataItems = {};
			for (var id in datas) {
				dataItems[id] = this.createDataItem(datas[id], conf, id);
			}
			return dataItems;
		}
		,convertIdsToItem: function(parentItem, dataItems, idsKey, itemsKey) {
			var items = [];
			var ids = parentItem[idsKey];
			for (var idx = 0, max = ids.length; idx < max; idx++) {
				items.push(dataItems[ids[idx]]);
			}
			parentItem[itemsKey] = items;
			delete parentItem[idsKey];
		}
		,convertIdsToItems: function(parentItems, dataItems, idsKey, itemsKey) {
			for (var id in parentItems) {
				this.convertIdsToItem(parentItems[id], dataItems, idsKey, itemsKey);
			}
		}
	};

	// 共通: 保存した検索条件管理API関連
	Nifty.Utils.SavedSearchConditionApi = {
		_ServiceKeys: {
			RENT: 'rent'
			,BUY: 'buy'
			,BUILDING: 'building'
		}
		,_SortKeys: {
			CREATED_AT_ASC: 'created_at' // 作成日時
			,CREATED_AT_DESC: '-created_at'
			,NEW_ARRIVALS_COUNT_ASC: 'new_arrivals_count' // 新着件数
			,NEW_ARRIVALS_COUNT_DESC: '-new_arrivals_count'
			,NEWEST_PROPERTY_REGISTERED_AT_ASC: 'newest_property_registered_at' // 最も新しい物件の登録日時
			,NEWEST_PROPERTY_REGISTERED_AT_DESC: '-newest_property_registered_at'
		}
		// private
		,_getPath: function(deviceId, conditionId) {
			// 引数の conditionId は任意
			var path = '/user/mypage/condition/api/' + deviceId;
			if (conditionId) {
				path += '/' + conditionId;
			}
			return path;
		}

		/**
		 * WebStorageに保存している型の条件のオブジェクトから、APIのレスポンス型の条件のオブジェクトを作成
		 * @param {?string} service (undefined,null可) Nifty.Utils.SavedSearchConditionApi._ServiceKeys で指定
		 * @param {?object} condition (undefined,null可) WebStorageで保存されている型の検索条件のオブジェクト
		 * @param {?boolean} canMailNewArrivals (undefined,null可) 新着メールを受信するかどうか
		 * @param {?boolean} shouldNotifyNewMansionBuy (undefined,null可) 販売物件の新着メール通知を受け取るか (未指定の場合はfalse)
		 * @param {?boolean} shouldNotifyNewMansionRent (undefined,null可) 賃貸物件で新着メール通知を受け取るか (未指定の場合はfalse)
		 */
		,_makeApiRequestCondition(service, condition, canMailNewArrivals, shouldNotifyNewMansionBuy, shouldNotifyNewMansionRent) {
			var result = {};
			var searchConditionDisplay = {};
			if(service != null) {
				result.service = service;
			}
			if(canMailNewArrivals != undefined && canMailNewArrivals != null) {
				result.mail_new_arrivals = canMailNewArrivals;
			}
			if(condition == null) {
				return result;
			}
			if(condition.url != undefined) {
				result.search_condition_url = condition.url;
			}
			if(service == 'buy') {
				if (condition.search_condition_display) {
					searchConditionDisplay = condition.search_condition_display
				}
			} else if(service == 'building') {
				if (condition.search_condition_display) {
					searchConditionDisplay = condition.search_condition_display
				}
				if(condition.url != undefined) {
					let serviceIdList = [];
					if(shouldNotifyNewMansionBuy) {
						// 「販売物件」にチェックを入れた場合
						serviceIdList.push('bnc','buc');
					}
					if(shouldNotifyNewMansionRent) {
						// 「賃貸物件」にチェックを入れた場合
						serviceIdList.push('rdw');
					}
					// URLパラメータ追加
					result.search_condition_url = Nifty.Utils.Url.removeParams(result.search_condition_url, ['subtype'])
					result.search_condition_url = Nifty.Utils.Url.addParam(result.search_condition_url, 'subtype=' + serviceIdList.join(','));
				}
			} else {
				if(condition.area != undefined) {
					searchConditionDisplay.area = condition.area;
				}
				if(condition.layout != undefined) {
					searchConditionDisplay.layout = condition.layout;
				}
				if(condition.rent != undefined) {
					searchConditionDisplay.cost = condition.rent;
				}
				if(condition.cond != undefined) {
					searchConditionDisplay.features = condition.cond;
				}
				if(condition.sort != undefined) {
					searchConditionDisplay.sort = condition.sort;
				}
			}
			if(Object.keys(searchConditionDisplay).length) {
				result.search_condition_display = searchConditionDisplay;
			}
			return result;
		}

		//public
		,getDeviceId: function() {
			// サイトではTreasure DataのclientId(uuid形式)をそのままAPIでのdeviceId として使う
			// 存在しないときときは ""
			// AMPページのclientIdは(amp-{22桁のbase64})となりPinpoint新着メール送信に支障が出るため、このタイミングで小文字変換を行っている
			// (device_idに大文字があるとPinpoint新着メール送信に失敗してしまう)
			return Nifty.Utils.Cookie.get('_td').toLowerCase();
		}
		/**
		 * 【賃貸】APIのレスポンス型の条件のオブジェクトから、WebStorageに保存している型の条件のオブジェクトを作成
		 * @param {Object} condition APIのレスポンス型の検索条件のオブジェクト
		 */
		,makeRentStorageFormatCondition: function(condition) {
			return {
				"date": Nifty.Utils.Date.format(new Date(condition.created_at), 'yyyy年MM月dd日'),
				"id": condition.condition_id,
				"url": condition.search_condition_url,
				"area": condition.search_condition_display.area,
				"rent": condition.search_condition_display.cost,
				"layout": condition.search_condition_display.layout,
				"cond": condition.search_condition_display.features,
				"sort": condition.search_condition_display.sort,
			};
		}
		/**
		 * 【賃貸】APIのレスポンス型の条件のオブジェクトの配列から、WebStorageに保存している型の条件のオブジェクトの配列を作成
		 * @param {object[]} conditions APIのレスポンス型の検索条件のオブジェクトの配列
		 */
		,makeRentStorageFormatConditions: function(conditions) {
			var self = this;
			return conditions.map(function(condition) {
				return self.makeRentStorageFormatCondition(condition);
			});
		}

		/**
		 * 【売買】APIのレスポンス型の条件のオブジェクトから、WebStorageに保存している型の条件のオブジェクトを作成
		 * @param {Object} condition APIのレスポンス型の検索条件のオブジェクト
		 */
		,makeBuyStorageFormatCondition: function(condition) {
			return {
				"date": Nifty.Utils.Date.format(new Date(condition.created_at), 'yyyy年MM月dd日'),
				"id": condition.condition_id,
				"url": condition.search_condition_url,
				"search_condition_display": condition.search_condition_display
			};
		}
		/**
		 * 【売買】APIのレスポンス型の条件のオブジェクトの配列から、WebStorageに保存している型の条件のオブジェクトの配列を作成
		 * @param {object[]} conditions APIのレスポンス型の検索条件のオブジェクトの配列
		 */
		,makeBuyStorageFormatConditions: function(conditions) {
			var self = this;
			return conditions.map(function(condition) {
				return self.makeBuyStorageFormatCondition(condition);
			});
		}

		/**
		 * 【マンション】APIのレスポンス型の条件のオブジェクトから、WebStorageに保存している型の条件のオブジェクトを作成
		 * @param {Object} condition APIのレスポンス型の検索条件のオブジェクト
		 */
		,makeMansionStorageFormatCondition: function(condition) {
			return {
				"date": Nifty.Utils.Date.format(new Date(condition.created_at), 'yyyy年MM月dd日'),
				"id": condition.condition_id,
				"url": condition.search_condition_url,
				"search_condition_display": condition.search_condition_display
			};
		}
		/**
		 * 【マンション】APIのレスポンス型の条件のオブジェクトの配列から、WebStorageに保存している型の条件のオブジェクトの配列を作成
		 * @param {object[]} conditions APIのレスポンス型の検索条件のオブジェクトの配列
		 */
		,makeMansionStorageFormatConditions: function(conditions) {
			var self = this;
			return conditions.map(function(condition) {
				return self.makeMansionStorageFormatCondition(condition);
			});
		}

		/**
		 * APIから全ての条件を取得（データ移行なし）
		 * @param {?string} service (undefined,null可) Nifty.Utils.SavedSearchConditionApi._ServiceKeys で指定
		 * @param {?string} sort Nifty.Utils.SavedSearchConditionApi._SortKeys で指定
		 * リトライ処理はなし
		 * jQueryのチェーンメソッド .done(function(response){}).fail(function(xhr){}).always(function(){}) をつなげて使う
		 */
		,fetchAllConditions: function(service, sort) {
			var deviceId = this.getDeviceId();
			var url = this._getPath(deviceId);
			if(service){
				url = Nifty.Utils.Url.addParam(url, 'service=' + service);
			}
			if(sort){
				url = Nifty.Utils.Url.addParam(url, 'sort=' + sort);
			}
			return $.ajax({
				url: url,
				type: 'get',
				cache: false,
				dataType:'json'
			});
		}

		/**
		 * 条件の配列をAPIに格納
		 * リトライ処理はなし
		 * @param {Object[]} conditions (必須) WebStorageで保存されている型の検索条件のオブジェクトの配列
		 * @param {string} service (必須) 'rent' / 'buy' / 'building'
		 * 注意: mail_new_arrivals は設定しない
		 * jQueryのチェーンメソッド .done(function(response){}).fail(function(xhr){}).always(function(){}) をつなげて使う
		 */
		,storeConditions : function(conditions, service) {
			var deviceId = this.getDeviceId();
			var self = this;
			var apiRequestConditions = conditions.map(function(condition) {
				return self._makeApiRequestCondition(service, condition, null, null, null);
			});
			return $.ajax({
				url: this._getPath(deviceId),
				type: 'post',
				cache: false,
				dataType:'json',
				contentType: 'application/json',
				data: JSON.stringify(apiRequestConditions)
			});
		}

		/**
		 * 条件をAPIに格納
		 * リトライ処理はなし
		 * @param {Object} condition (必須) WebStorageで保存されている型の検索条件のオブジェクト
		 * @param {string} service (必須) 'rent' / 'buy' / 'building'
		 * @param {?boolean} canMailNewArrivals (undefined,null可) この条件で新着メール通知を受け取るか (未指定の場合はfalse)
		 * @param {?boolean} shouldNotifyNewMansionBuy (undefined,null可) 販売物件の新着メール通知を受け取るか (未指定の場合はfalse)
		 * @param {?boolean} shouldNotifyNewMansionRent (undefined,null可) 賃貸物件で新着メール通知を受け取るか (未指定の場合はfalse)
		 * jQueryのチェーンメソッド .done(function(response){}).fail(function(xhr){}).always(function(){}) をつなげて使う
		 */
		,storeCondition : function(condition, service, canMailNewArrivals, shouldNotifyNewMansionBuy, shouldNotifyNewMansionRent) {
			var deviceId = this.getDeviceId();
			var apiRequestCondition = this._makeApiRequestCondition(service, condition, canMailNewArrivals, shouldNotifyNewMansionBuy, shouldNotifyNewMansionRent);
			return $.ajax({
				url: this._getPath(deviceId),
				type: 'post',
				cache: false,
				dataType:'json',
				contentType: 'application/json',
				data: JSON.stringify([apiRequestCondition])
			});
		}

		/**
		 * API上の条件を更新
		 * リトライ処理はなし
		 * @param {string} conditionId (必須) APIで発行される条件のID
		 * @param {?Object} condition (undefined,null可) WebStorageで保存されている型の検索条件のオブジェクト
		 * @param {?boolean} canMailNewArrivals (undefined,null可) 新着メールを受信するかどうか
		 * @param {?boolean} shouldNotifyNewMansionBuy (undefined,null可) 販売物件の新着メール通知を受け取るか (未指定の場合はfalse)
		 * @param {?boolean} shouldNotifyNewMansionRent (undefined,null可) 賃貸物件で新着メール通知を受け取るか (未指定の場合はfalse)
		 * jQueryのチェーンメソッド .done(function(response){}).fail(function(xhr){}).always(function(){}) をつなげて使う
		 *
		 * API仕様の補足:
		 *   送信したJSONに存在するノードのみ上書きされる
		 *   例えば、 {"mail_new_arrivals": true} だけのJSONを送信しても search_condition など、他のデータは削除されない
		 */
		,updateCondition: function(conditionId, condition, canMailNewArrivals, service, shouldNotifyNewMansionBuy, shouldNotifyNewMansionRent) {
			var body = this._makeApiRequestCondition(service, condition, canMailNewArrivals, shouldNotifyNewMansionBuy, shouldNotifyNewMansionRent);
			var deviceId = this.getDeviceId();
			return $.ajax({
				url: this._getPath(deviceId, conditionId),
				type: 'patch',
				cache: false,
				dataType:'json',
				contentType: 'application/json',
				data: JSON.stringify(body)
			});
		}

		/**
		 * 条件をAPIから削除
		 * リトライ処理はなし
		 * @param {string} id (必須) 保存した検索条件のAPI上のID
		 * jQueryのチェーンメソッド .done(function(response){}).fail(function(xhr){}).always(function(){}) をつなげて使う
		 */
		,destroyCondition: function(id) {
			var deviceId = this.getDeviceId();
			return $.ajax({
				url: this._getPath(deviceId, id),
				type: 'delete',
			});
		}

		/**
		 * 【共通】エラーメッセージ生成
		 * @param Object xhr $.ajax.fail時のXMLHttpRequest
		 * @param Object item Local Storage で格納する型の検索条件の Object
		 */
		,makeSaveConditionErrorMessage: function(xhr, item) {
			// 検索条件を保存時
			if (xhr && xhr.status == '400') {
				// 400エラー(必須項目が無い or device_id あたり101件以上の検索条件を登録しようとしている場合)
				if (item && item.url) {
					// 101件目保存時のみ、エラーメッセージを変える
					return '保存できる検索条件は最大100件までです。この条件を保存する為には、現在保存されている他の条件を削除してください。';
				}
			}
			// その他のエラー
			return '申し訳ありません。条件の保存中にエラーが発生しました。再度お試しください。';
		}
		,makeDeleteConditionErrorMessage: function(xhr, item) {
			// 検索条件を削除時(今後出しわける可能性を考慮してxhr, conditionを引数に受け取る)
			return '申し訳ありません。条件の削除中にエラーが発生しました。';
		}
		,makeUnsubscribeErrorMessage: function(xhr) {
			// 新着メール通知を停止時(今後出しわける可能性を考慮してxhrを引数に受け取る)
			return '申し訳ありません。設定中にエラーが発生しました。再度お試しください。';
		}
		,makeFetchConditionsErrorMessage: function(xhr) {
			// 検索条件取得時(今後出しわける可能性を考慮してxhrを引数に受け取る)
			return '申し訳ありません。保存した検索条件の取得に失敗しました。ページを再読み込みしてください。';
		}
	};

	// 共通: メール通知設定管理API関連
	Nifty.Utils.MailNotificationApi = {

		// private
		_getUrl: function(deviceId) {
				// 引数の deviceId は任意
				var domain = 'https://mail-notification.api.myhome.nifty.com';
				if (Nifty.Utils.Env.isDev()) {
						// 開発環境ドメインをフルでのべた書き防止の為、replaceで書き換え
						domain = domain.replace('api', 'api.dev');
				}
				var url = domain + '/v1/destination/';
				if (deviceId) {
						url += deviceId;
				}
				return url;
		}

		//public
		,getDeviceId: function() {
			// サイトではTreasure DataのclientId(uuid形式)をそのままAPIでのdeviceId として使う
			// 存在しないときときは ""
			// AMPページのclientIdは(amp-{22桁のbase64})となりPinpoint新着メール送信に支障が出るため、このタイミングで小文字変換を行っている
			// (device_idに大文字があるとPinpoint新着メール送信に失敗してしまう)
			return Nifty.Utils.Cookie.get('_td').toLowerCase();
		}
		/**
		 * APIから送信先を取得
		 * リトライ処理はなし
		 * @param {function} successFunc (必須) 成功時のcallback
		 * @param {function} errorFunc (必須) エラー時のcallback
		 */
		,fetchDestination : function(successFunc, errorFunc) {
			var self = this;
			if (!self._isFetchDestinationStarted) {
				// fetch未開始
				self._isFetchDestinationStarted = true;
				self._fetchDestinationSuccessFunctions.push(successFunc);
				self._fetchDestinationErrorFunctions.push(errorFunc);

				var deviceId = self.getDeviceId();
				$.ajax({
					url: self._getUrl(deviceId),
					type: 'get',
					cache: false,
					dataType:'json'
				}).done(function(result) {
					self._fetchDestinationResult = result;
					for (var idx = 0, max = self._fetchDestinationSuccessFunctions.length; idx < max; idx++) {
						self._fetchDestinationSuccessFunctions[idx](result);
					}
				}).fail(function() {
					self._isFetchDestinationFailed = true;
					for (var idx = 0, max = self._fetchDestinationErrorFunctions.length; idx < max; idx++) {
						self._fetchDestinationErrorFunctions[idx]();
					}
				}).always(function() {
					self._isFetchDestinationEnded = true;
				});
			} else if (!self._isFetchDestinationEnded) {
				// fetch開始済、未完了
				self._fetchDestinationSuccessFunctions.push(successFunc);
				self._fetchDestinationErrorFunctions.push(errorFunc);
			} else {
				// fetch完了済
				if (self._isFetchDestinationFailed) {
					errorFunc();
				} else {
					successFunc(self._fetchDestinationResult);
				}
			}
		}
		// 多重リクエスト防止制御のための変数
		,_fetchDestinationResult: null
		,_isFetchDestinationStarted: false
		,_isFetchDestinationEnded: false
		,_isFetchDestinationFailed: false
		,_fetchDestinationSuccessFunctions: []
		,_fetchDestinationErrorFunctions: []

		// 賃貸/売買のNewArrivalMailModalでメールアドレス登録/更新を共有する為の変数、イベント
		,_destinationChangedTriggerName: 'mail-notification-destination-changed'
		,changedDestinationResult: null
		,setDestinationChangedEvent: function(func) {
			$(document).on(this._destinationChangedTriggerName, func);
		}

		/**
		 * 通知先をAPIに格納
		 * リトライ処理はなし
		 * @param {string} email (必須) 登録するメールアドレス
		 * @param {boolean} optinNewArrivals (必須) 新着メールを受信するか
		 * @param {boolean} optinOthers (必須) メールを受信するか
		 * jQueryのチェーンメソッド .done(function(response){}).fail(function(xhr){}).always(function(){}) をつなげて使う
		 */
		,storeDestination : function(email, optinNewArrivals, optinOthers, service) {
			var self = this;
			var deviceId = this.getDeviceId();
			var body = {
				platform: Nifty.Utils.Env.isSmp() ? 'smp' : 'pc',
				service: service,  // serviceは初回登録時のみ送信(賃貸/売買でAPI_DBのデータは共通の為)
				device_id: deviceId,
				email: email,
				optin_new_arrivals: optinNewArrivals,
				optin_others: optinOthers
			};
			return $.ajax({
					url: this._getUrl(),
					type: 'post',
					cache: false,
					dataType:'json',
					contentType: 'application/json',
					data: JSON.stringify(body)
			}).done(function(response) {
				self.changedDestinationResult = response;
				$(document).trigger(self._destinationChangedTriggerName);
			});
		}

		/**
		 * API上の通知先を更新
		 * リトライ処理はなし
		 * @param {?string} email (undefined,null可) 登録するメールアドレス
		 * @param {?boolean} optinNewArrivals (undefined,null可) 新着メールを受信するか
		 * @param {?boolean} optinOthers (undefined,null可) メールを受信するか
		 */
		,updateDestination : function(email, optinNewArrivals, optinOthers) {
			var self = this;
			var deviceId = this.getDeviceId();
			var body = {
				platform: Nifty.Utils.Env.isSmp() ? 'smp' : 'pc',
				device_id: deviceId,
			}
			if (email) {
				body.email = email;
			}
			if (optinNewArrivals) {
				body.optin_new_arrivals = optinNewArrivals;
			}
			if (optinOthers) {
				body.optin_others = optinOthers;
			}
			return $.ajax({
					url: this._getUrl(deviceId),
					type: 'patch',
					cache: false,
					dataType:'json',
					contentType: 'application/json',
					data: JSON.stringify(body)
			}).done(function(response) {
				self.changedDestinationResult = response;
				$(document).trigger(self._destinationChangedTriggerName);
			});
		}
	};

	// 共通: 画面関連
	Nifty.Utils.Page = {
		// 指定要素までスクロール
		scrollTo: function($dest, topMargin) {
			if ($dest.length > 0) {
				$('html,body').animate({ scrollTop: $dest.offset().top - (topMargin ? topMargin : 0) }, 'normal');
			}
		}
		// 指定要素までスクロール(ウィンドウ位置が指定要素より下の場合のみ)
		,scrollToIfUnder: function($dest, topMargin) {
			if ($dest.length > 0 && $(window).scrollTop() > $dest.offset().top) {
				this.scrollTo($dest, topMargin);
			}
		}
	};

	// 共通: LocalStorage関連
	Nifty.Utils.Storage = {
		// Key-Value形式で保存する際に使用するキー名(iOSのCookie対策の為に、LocalStorageをCookie代わりに使用する際などに使用)
		_KEY_NAME_KEY_VALUE: 'key-value'

		,_data : {}
		,_set : function(obj) {
			try {
				localStorage.setItem(this._key, JSON.stringify(obj));
			} catch (e) {}
		}
		,_remove : function(obj) {
			try {
				localStorage.removeItem(this._key, JSON.stringify(obj));
			} catch (e) {}
		}
		,_get : function() {
			var json;
			try {
				json = localStorage.getItem(this._key);
			} catch (e) {}

			var data = (json) ? JSON.parse(json) : {};
			if (!data.version) {
				data.version = this._version;
			}
			for (var key in this._conf) {
				if (!data[key]) {
					data[key] = [];
				}
			}
			if (!data[this._KEY_NAME_KEY_VALUE]) {
				data[this._KEY_NAME_KEY_VALUE] = {};
			}
			return data;
		}

		// 処理処理(必須)
		,init : function(key, version, conf) {
			this._key = key;
			this._version = version;
			this._conf = conf;  // { 識別子 : 最大保存数 } を指定
		}

		,clear : function() {
			this._remove();
		}
		,getVersion : function() {
			var data = this._get();
			return (data) ? data.version : '';
		}
		,getItems : function(key) {
			var data = this._get();
			return (data && data[key]) ? data[key] : [];
		}
		,getItemsMax : function(key) {
			return (this._conf[key]) ? this._conf[key] : 0;
		}
		,setItems : function(key, items) {
			if (items) {
				var storage = this._get();
				if (storage[key]) {
					storage[key] = items;
					storage[key].splice(this.getItemsMax(key));
					this._set(storage);
				}
			}
		}
		,indexOf : function(key, id) {
			var list = this.getItems(key);
			for (var idx = 0; idx < list.length; idx++) {
				if (list[idx] && list[idx].id === id) {
					return idx;
				}
			}
			return -1;
		}
		,getItem : function(key, id) {
			var idx = this.indexOf(key, id);
			return (idx >= 0) ? this.getItems(key)[idx] : null;
		}
		,addItem : function(key, item) {
			if (item) {
				var storage = this._get();
				if (storage[key]) {
					var idx = this.indexOf(key, item.id);
					if (idx >= 0) {
						storage[key].splice(idx, 1);
					}
					storage[key].unshift(item);
					storage[key].splice(this.getItemsMax(key));
					this._set(storage);
				}
			}
		}
		,updateItem : function(key, item) {
			if (item) {
				var storage = this._get();
				if (storage[key]) {
					var idx = this.indexOf(key, item.id);
					if (idx >= 0) {
						storage[key][idx] = item;
						this._set(storage);
					}
				}
			}
		}
		,removeItem : function(key, id) {
			var idx = this.indexOf(key, id);
			if (idx >= 0) {
				var storage = this._get();
				storage[key].splice(idx, 1);
				this._set(storage);
			}
		}
		,removeItemIf : function(key, funcIf) {
			var storage = this._get();
			var count = 0;
			for (var idx = storage[key].length - 1; idx >= 0; idx--) {
				if (funcIf(storage[key][idx])) {
					storage[key].splice(idx, 1);
					count++;
				}
			}
			this._set(storage);
			return count;
		}

		// Key-Value保存用
		,getKeyValue : function(key) {
			var storage = this._get();
			return storage[this._KEY_NAME_KEY_VALUE][key];
		}
		,setKeyValue : function(key, value) {
			var storage = this._get();
			storage[this._KEY_NAME_KEY_VALUE][key] = value;
			this._set(storage);
		}
		,removeKeyValue : function(key) {
			var storage = this._get();
			delete storage[this._KEY_NAME_KEY_VALUE][key];
			this._set(storage);
		}
	};

	/**
	 *  汎用モーダル管理
	 *
	 * HTMLテンプレートの要素に以下を設定し各関数を実行することで、モーダルとして登録され動作の権限が与えられる
	 *
	 * テンプレート側に含める要素
	 * - data-micromodal-content: モーダル全体を覆う要素に設定し、コンテンツとしてモーダル名を記述すること。
	 *     ※ 同エレメントに aria-hidden="false" を設定すること(音声読み上げ設定)
	 *     ※ 正しく登録されていない場合モーダルとして登録されず動作しないため注意
	 * - data-micromodal-close: ユーザが押下した際にモーダルを閉じるトリガーとなる要素に設定すること。
	 *
	 */
	Nifty.Utils.ModalRepository = {
		// 定義
		DataMicromodal: {
			Content: 'data-modal-content' ,
			Close: 'data-modal-close'
		}
		,_modalMap: {} // ページに存在するモーダル一覧
		/**
		 * モーダル名を指定し、一覧に登録 (他の関数の前に実行すること)
		 * 以下の条件を満たさない場合は登録失敗
		 * - 指定のモーダルが存在しない (data-modal-contentが正しく定義されていない)
		 * - モーダルが既に登録済み
		 * - aria-hidden属性が正しく定義されていない
		 *
		 * @param {String} modalName モーダル名
		 * @return 登録の成功時はtrue/失敗時はfalse
		 */
		,register: function(modalName) {
			if(!modalName || modalName in this._modalMap) return false;

			const $modalElement = $(`[${this.DataMicromodal.Content}="${modalName}"]`);
			if($modalElement.length != 1 || typeof $modalElement.attr('aria-hidden') == 'undefined') return false;

			this._setCloseEvent($modalElement);

			this._modalMap[modalName] = $modalElement;
			return true;
		}
		/**
		 * モーダルを起動
		 *
		 * @param modalName モーダル名
		 * @return 起動成功時はtrue/失敗時はfalse
		 */
		,activate: function(modalName) {
			if(!(modalName && modalName in this._modalMap)) return false;

			const $modalElement = this._modalMap[modalName];
			this._switchShowHide($modalElement, true);
			return true;
		}
		/**
		 * モーダルを停止
		 *
		 * @param modalName モーダル名
		 * @return 起動成功時はtrue/失敗時はfalse
		 */
		,deactivate: function(modalName) {
			if(!(modalName && modalName in this._modalMap)) return false;

			const $modalElement = this._modalMap[modalName];
			this._switchShowHide($modalElement, false);
			return true;
		}
		/**
		 * 独自の属性と押下時のイベントを設定
		 *
		 * @param modalName モーダル名
		 * @param attrName 属性名
		 * @param eventFunc 押下時のイベント
		 * @return 設定成功時はtrue/失敗時はfalse
		 */
		,setAttrClickEvent: function(modalName, attrName, eventFunc) {
			if(!(modalName && modalName in this._modalMap)) return false;

			const $targetElement = this._modalMap[modalName].find(`[${attrName}]`);
			if($targetElement.length == 0) return false;

			$.each($targetElement,(_, element) => {
				$(element).on('click', eventFunc);
			});

			return true;
		}
		/**
		 * モーダルを取得
		 *
		 * @param modalName モーダル名
		 * @return 成功:モーダルオブジェクト/失敗:null
		 */
		,getModalElement: function(modalName) {
			if(!(modalName && modalName in this._modalMap)) return null;

			const $modalElement = this._modalMap[modalName];
			return $modalElement;
		}
		/**
		 * data-modal-contentと同じタグ内に付与された属性値を取得
		 * サーバサイドから値を渡す際などに利用
		 *
		 * @param modalName モーダル名
		 * @param attrName 属性名
		 */
		,getModalContainerAttrValue: function(modalName, attrName) {
			const $modalElement = this.getModalElement(modalName);
			if(!$modalElement) return '';

			return $modalElement.attr(`${attrName}`) ?? '';
		}
		/**
		 * モーダルの閉じるイベントを設定
		 *
		 * @param {Object} $modalElement モーダル要素
		 */
		,_setCloseEvent: function($modalElement) {
			const $closeElement = $modalElement.find(`[${this.DataMicromodal.Close}]`);
			$.each($closeElement, (_, element) => {
				$(element).on('click', () => { this._switchShowHide($modalElement, false); });
			});
		}
		/**
		 * 表示・非表示切り替え
		 *
		 * @param {Object} $modalElement モーダル要素
		 * @param {Boolean} shouldShow 表示:true/非表示:false
		 */
		 ,_switchShowHide: function($modalElement, shouldShow) {
			if(shouldShow) {
				$modalElement.addClass('is-active');
				$modalElement.attr('aria-hidden', false);
			}
			else {
				$modalElement.removeClass('is-active');
				$modalElement.attr('aria-hidden', true);
			}
		}
	};

	/**
	 * 画像ループ
	 *
	 * 実装方法
	 * 1. 特定の属性を持ったDOMを定義
	 * ```
	 * <div data-img-loop
	 *   data-img-loop-config-speed="40000">
	 *   <div data-img-loop-inner>
	 *     <ul data-img-loop-list>
	 *       <li>
	 *         <img src="/path/to/example.png" alt="" width="" height=""/>
	 *       </li>
	 *     </ul>
	 *   </div>
	 * </div>
	 * ```
	 * 設定する属性は以下の通り
	 * - data-img-loop: 必須
	 * - data-img-loop-inner: 必須
	 * - data-img-loop-list: 必須
	 * - data-img-loop-config-axis: 任意。X(横回転) or Y(縦回転) を値に指定。デフォルトはX。
	 * - data-img-loop-config-direction: 任意。normal（順回転） or reverse(逆回転) を値に指定。デフォルトはnormal。
	 * - data-img-loop-config-speed: 任意。回転速度を10000(速)~100000(遅)程度で値に指定。デフォルトは30000。
	 * - data-img-loop-config-has-limit: 任意。true or false (ループ要素を画面幅の2倍以上に増やすか否か) を値に指定。デフォルトはfalse。※縦回転時はブラウザがクラッシュするため使用禁止。
	 *
	 * 詳細な説明はこちらを参考：https://github.com/qrac/showloop
	 *
	 * 2. 以下を実行
	 * Nifty.Utils.ImgLoop.run()
	 */
	Nifty.Utils.ImgLoop = {
		_config: {
			stageAttr: "data-img-loop",
			slideAttr: "data-img-loop-inner",
			itemsAttr: "data-img-loop-list",
			configAxisAttr: "data-img-loop-config-axis",
			configDirectionAttr: "data-img-loop-config-direction",
			configSpeedAttr: "data-img-loop-config-speed",
			configHasLimitAttr: "data-img-loop-config-has-limit"
		},
		_mount(el, options) {
			const { axis, direction, speed, hasLimit } = options;
			el.stage.style.overflow = "hidden";
			el.stage.style.display = "flex";
			el.slide.style.display = "flex";
			if (axis === "Y") {
				el.slide.style.flexDirection = "column";
			}
			let limitSize = 0;
			let stageSize = 0;
			let slideSize = 0;
			let itemsSize = 0;
			let wantItems = 0;
			let cloneItems = 0;
			let shortItems = 0;
			let needItems = 0;
			const setup = (contentSize) => {
				limitSize = axis === "X" ? window.innerWidth * 2 : window.innerHeight * 2;
				stageSize = contentSize;
				slideSize = axis === "X" ? el.slide.scrollWidth : el.slide.scrollHeight;
				if(slideSize == 0) return;
				itemsSize = axis === "X" ? el.items.scrollWidth : el.items.scrollHeight;
				if(itemsSize == 0) return;
				wantItems = Math.ceil(stageSize * 2 / itemsSize);
				cloneItems = [...el.slide.querySelectorAll(`[${this._config.itemsAttr}]`)].length - 1;
				shortItems = wantItems - cloneItems;
				needItems = slideSize >= limitSize ? cloneItems === 0 ? 1 : 0 : shortItems;
				needItems = hasLimit ? needItems : shortItems;
				for (let step = 0; step < needItems; step++) {
					const clone = el.items.cloneNode(true);
					el.slide.appendChild(clone);
				}
				const addedSlideSize = axis === "X" ? el.slide.scrollWidth : el.slide.scrollHeight;
				const overSize = addedSlideSize - stageSize;
				const start = direction === "normal" ? 0 : -1 * overSize;
				const end = direction === "normal" ? -1 * itemsSize : -1 * (overSize - itemsSize);
				el.slide.animate(
				{
					transform: [
					`translate${axis}(${start}px)`,
					`translate${axis}(${end}px)`
					]
				},
				{
					iterations: Infinity,
					duration: speed
				}
				);
			}
			setup(el.stage.clientWidth);
		},
		run() {
			const targets = [
				...document.querySelectorAll(`[${this._config.stageAttr}]`)
			];
			if (!targets.length) {
				return;
			}
			targets.map((target) => {
				const stage = target.hasAttribute(this._config.stageAttr) ? target : target.querySelector(`[${this._config.stageAttr}]`);
				const slide = target.querySelector(`[${this._config.slideAttr}]`);
				const items = target.querySelector(`[${this._config.itemsAttr}]`);

				const axis = target.getAttribute(`${this._config.configAxisAttr}`) || 'X';
				const direction = target.getAttribute(`${this._config.configDirectionAttr}`) || 'normal';
				const speed = parseInt(target.getAttribute(`${this._config.configSpeedAttr}`) || '30000');
				const hasLimit = target.getAttribute(`${this._config.configHasLimitAttr}`) == 'true';

				if (stage && slide && items) {
					return this._mount({ stage, slide, items }, {
						axis, direction, speed, hasLimit
					});
				}
				return;
			});
		}
	};

	// 共通: バリデーション
	Nifty.Utils.Validation = {
		isValidEmail: function(email) {
			// jquery.validate.min.js に含まれるメールアドレスチェックの正規表現を流用
			// RFC準拠していないドコモのメールアドレスにも対応 (例: docomo..ab1234yz@docomo.ne.jp、docomo-ab1234yz.@docomo.ne.jp)
			var emailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
			return emailRegex.test(email);
		}
	};

	// バリデーションルール
	// 賃貸問い合わせフォームと合わせている
	// https://github.com/niftylifestyle/myhome-rent-web-inquiry/blob/develop/src/main/resources/static/rent/js/inquiry-form-validation.js
    Nifty.Utils.ValidationRule = {};
    Nifty.Utils.ValidationRule.rules = {
        "lastName": {
            "required": true,
            "maxlength": 10
        },
        "firstName": {
            "required": true,
            "maxlength": 10
        },
        "email": {
            "required": true,
            "maxlength": 50,
            "pattern": "^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[-_0-9a-z]+(\\.[-_0-9a-z]+)+$",
            "email": true
        },
        "inquiryCategories": {
            "required": true
        },
        "phoneNumber": {
            "pattern": "^0(?!0|120|570|800|990)[0-9]*$",
            "minlength": 10,
            "maxlength": 11
        },
        "remarks": {
            "minlength": 0,
            "maxlength": 100
        },
        "visitReservationRemarks": {
            "minlength": 0,
            "maxlength": 100
        },
        "propertyCount": {
            "required": true,
            "min": 1
        }
    };
    Nifty.Utils.ValidationRule.messages = {
        "lastName": {
            "required": "※姓を入力してください",
            "maxlength": "※姓は" + Nifty.Utils.ValidationRule.rules.lastName.maxlength + "文字以内で入力してください"
        },
        "firstName": {
            "required": "※名を入力してください",
            "maxlength": "※名は" + Nifty.Utils.ValidationRule.rules.firstName.maxlength + "文字以内で入力してください"
        },
        "email": {
            "required": "※メールアドレスを入力してください",
            "maxlength": "※メールアドレスは" + Nifty.Utils.ValidationRule.rules.email.maxlength + "文字以内で入力してください",
            "pattern": "※メールアドレスはすべて半角で、また@以降は全て小文字で入力してください",
            "email": "※正しいメールアドレスを入力してください"
        },
        "inquiryCategories": {
            "required": "※お問い合わせ内容を選択してください"
        },
        "phoneNumber": {
            "required": "※電話番号を入力してください",
            "pattern": "※電話番号はハイフンを入れず、すべて半角数字で入力してください。また、フリーダイヤル、ナビダイヤル等の番号はご利用になれません",
            "minlength": "※電話番号は" + Nifty.Utils.ValidationRule.rules.phoneNumber.minlength + "文字から" + Nifty.Utils.ValidationRule.rules.phoneNumber.maxlength + "文字以内で入力してください",
            "maxlength": "※電話番号は" + Nifty.Utils.ValidationRule.rules.phoneNumber.minlength + "文字から" + Nifty.Utils.ValidationRule.rules.phoneNumber.maxlength + "文字以内で入力してください"
        },
        "remarks": {
            "maxlength": "※備考欄は" + Nifty.Utils.ValidationRule.rules.remarks.maxlength + "文字以内で入力してください"
        },
        "visitReservationRemarks": {
            "maxlength": "※ご希望は" + Nifty.Utils.ValidationRule.rules.visitReservationRemarks.maxlength + "文字以内で入力してください"
        },
        "propertyCount": {
            "min": "※物件を" + Nifty.Utils.ValidationRule.rules.propertyCount.min + "件以上選択してください"
        }
    };
});
